import { useState } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

const Contact = () => {

  
  
  const { register, reset, handleSubmit } = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState(null);

  const accessKey = "cca505bb-904d-42fc-afc1-723fba2f5154"; // Replace with your Web3Forms access key

  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: "Acme Inc",
      subject: "New Contact Message from your Website",
      // You can add other settings here
    },
    onSuccess: (msg) => {
      setIsSuccess(true);
      setResult(msg);
      reset();
    },
    onError: (msg) => {
      setIsSuccess(false);
      setResult(msg);
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder="Your Name"
          {...register("name", { required: true })}
        />
        <input
          type="email"
          placeholder="Your Email"
          {...register("email", { required: true })}
        />
        <textarea
          placeholder="Your Message"
          {...register("message", { required: true })}
        ></textarea>
        <button type="submit">Submit Form</button>
      </form>

      <div>
        {result && (
          <p style={{ color: isSuccess ? "green" : "red" }}>{result}</p>
        )}
      </div>
    </div>
  );
};

export default Contact;
