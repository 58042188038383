import React, { useEffect } from 'react';
import abort from '../img/IMG_6307.jpg';
import Footer from '../component/Footer';

const Familyplan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <>


      <div className='Heading'>
        <h1>Family Planning and Contraception Advice</h1>
        <hr className="styled-hr" />
      </div>


      <div className="familyplan-container">
        <div className="familyplan-header">



        </div>

        <div className="familyplan-content">
          <div className="familyplan-image">
            <img src={abort} alt="Family Planning and Contraception Advice" />
          </div>

          <div className="familyplan-text">

            <br/>
            <p>
            Family Planning and Contraception- We help to choose the best method of birth control for the couple. It can be done through medicines, devices or surgery. Some are temporary while some are permanent.
            </p>

            
           
          </div>
        </div>

      
        <Footer />
      </div>
    </>
  );
};

export default Familyplan;
