import React, { useEffect } from 'react';
import Footer from './Footer'
import Services2 from './Services2'
const Treatment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>

      <div className='Heading'>
        <h1>Services</h1>
        <hr className="styled-hr" />
        <p>If you need effective immediate assistance, emergency treatment or a simple consultation.</p>
      </div>


      <Services2 />

      <Footer />
    </>
  )
}

export default Treatment
