import React from 'react'
import doctor from '../img/doctor.jpeg'

const Doctorsection = () => {
    return (
        <>

            <div className='mydoct'>

                <div className='Obstetrician '>
                    <h1>Know Your Doctor</h1>
                    <hr className="custom-hr" />

                    <p>Dr. Juhi Sisodia is a skilled and experienced obstetrician and gynaecologist with a passion for providing comprehensive healthcare to women of all ages.</p>
                </div>

                <div className='doctorabout'>

                    <div className='digree1'>
                        <h1> <b>DEGREES</b></h1>

                        <hr className="custom-hr" />



                        <h2><b>MBBS -</b> <span>She did her MBBS from Vijaynagar Institute of Medical Sciences (Govt. Medical College), Bellary, Karnataka through All India PMT exam.</span>  </h2>
                        <h2><b>DNB (Obgyn) -</b> <span>She persued her Post Graduation in Obs and Gynae from <span style={{ color: 'purple' }}>Kurji Holy Family Hospital, Patna.</span></span> </h2>
                        <h2><b>Senior Residency-</b> <span>She further did her Senior Residency from <span style={{ color: 'purple' }}> Dr. Ram Manohar Lohia Institute of Medical Sciences , Lucknow. </span> </span> </h2>
                        <h2><b>Fellowship in Advanced Laparoscopic Surgeries  -</b> <span>She did fellowship in advanced Laparoscopic Surgeries from <span style={{ color: 'purple' }}>Nadkarni's Medical Training Academy, Gujrat. </span> </span>  </h2>
                        <h2><b>Infertility Management-</b> <span>Trained from </span> <span style={{ color: 'purple'  }}> Sir Ganga Ram Hospital, New Delhi.</span> </h2>
                        <div className='contexts'>
                            <p>For years she worked in  <span style={{ color: 'purple', textDecoration: 'underline', fontWeight: 'bold' }}>FATIMA HOSPITAL</span>  Lucknow both as a clinician & DNB Teacher.</p>

                            <p>She has more than 10 years of experience in managing all kinds of  Normal Pregnancy, High Risk Pregnancy and doing Gynaecological Surgeries .</p>
                            <p>She has special interest in academics & has several papers published in various journals.</p>
                        </div>

                    </div>

                    <div className='digree2   '>
                        
                        {/* <div className='doctorss'>
                          <h4>Dr. Juhi Sisodia</h4>
                          <p>MBBS, DNB(Obgyn), FMAS
                          Fellowship in Laparoscopy</p>
                          <p>Consultant Obstetrician , Gynaecologist and Laparoscopic Surgeon</p>
                        </div> */}
                    </div>

                </div>

            </div>



        </>
    )
}

export default Doctorsection
