import React from 'react'
import Lapro from '../img/lapro.jpg';
import High from '../img/high.jpg';
import Abortion from '../img/abortion.jpg';
import Gyneo from '../img/cancer screen.jpg';
import Vaginal from '../img/vaginal.jpg';
import Family from '../img/IMG_6307.jpg';

const Services2 = () => {
    return (
        <>
            <div className='served'>

                <div className='served2'>
                    <img src={Lapro} />
                    <h1>Laparoscopic Gynaecological
                        Procedures (keyhole surgery)</h1>
                    <p>Laparoscopic Gynaecological Procedures- Gynae Laparoscopy surgery is a minimally invasive procedure that allows the surgeon to operate inside abdomen by inserting instruments through small incisions. Benefits of this surgery includes less pain, less scarring, shorter hospital stay and fast recovery.</p>
                </div>

                <div className='served2'>
                    <img src={High} />
                    <h1> Normal & High-Risk Pregnancy</h1>
                    <p>Normal And High Risk Pregnancy- High risk pregnancy is a pregnancy where mother or fetus have a higher chance of experiencing complications than a normal pregnancy. Factors that contribute to high risk are - Medical condition , age , multiple pregnancy , life style pattern, etc.
                    </p>
                </div>

                <div className='served2'>
                    <img src={Family} />
                    <h1>Family Planning and Contraception Advice</h1>
                    <p>Family Planning and Contraception- We help to choose the best method of birth control for the couple. It can be done through medicines, devices or surgery. Some are temporary while some are permanent.</p>
                </div>


            </div>



            <div className='served'>

                <div className='served2'>
                    <img src={Gyneo} />
                    <h1>Gynaecology Cancer Screening & Vaccination</h1>
                    <p>Gyane Cancer  Screening & Vaccination- Cancer screening helps to detect cancer at early stage and have effective treatment at early stage itself. Ultrasound, tumor markers, liquid based cytology, HPV testing are included in this.
                    </p>
                    <p>Cervical cancer vaccination is a safe and effective way to prevent cervical cancer and Genital warts.</p>
                </div>

                <div className='served2'>
                    <img src={Vaginal} />
                    <h1>Vaginal Surgery</h1>
                    <p>Vaginal Surgery- In this the entire surgery is performed vaginally and hence there is no abdominal scar. Time for post - operative recovery is lesser .
                    </p>
                </div>

               

            </div>




        </>
    )
}

export default Services2
