import React from 'react'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom';

const Section = () => {
  return (
    <div className='section'>
      <img src={logo} />
      <h1>Dr. Juhi Sisodia</h1>
      <h2>MBBS, DNB(Obgyn), FMAS</h2>
      <h2>Fellowship in Laparoscopy</h2>

      <p>Consultant Obstetrician , Gynaecologist and Laparoscopic Surgeon </p>

      <h3>ASSOCIATED HOSPITALS</h3>
      <h4>   JIVISHA MEDICAL CENTRE</h4>
      <h4>   GOEL SUPER SPECIALITY HOSPITAL</h4>
      <h4>   CLOUDNINE HOSPITAL</h4>
      <p><b>Clinic Addrress :</b> 5/41 , Vinay Khand, Gomti Nagar, Lucknow - 226010 </p>
      <p><b>Phone. No: +91 6387313639</b></p>

      <Link to='/ContactForm' style={{ textDecoration: 'none' }}>
        <button>Book An Appointment</button>
      </Link>

    </div>
  )
}

export default Section
