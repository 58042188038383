import React from 'react';
import { Link } from 'react-router-dom';
import lapro from '../img/lapro.jpg';
import hrp from '../img/high.jpg';
import viginal from '../img/vaginal.jpg'
import abort from '../img/IMG_6307.jpg';
import cancer from '../img/cancer screen.jpg'

const Services = () => {
    return (
        <>
            <div className='service'>

                <div className='servi2'>
                    <img src={lapro} alt="Laparoscopic Gynaecological Procedures" />
                    <Link to='/Laproscop' style={{ textDecoration: 'none', color: 'black' }}>
                        <h2>Laparoscopic Gynaecological Procedures</h2>
                    </Link>
                </div>

                <div className='servi2'>
                    <img src={hrp} alt="High Risk Pregnancy" />
                    <Link to='/Highrisk' style={{ textDecoration: 'none', color: 'black' }}>
                        <h2> Normal & High Risk Pregnancy</h2>
                    </Link>
                </div>
            </div>
            <div className='service'>
                <div className='servi2'>
                    <img src={abort} alt="Safe Abortion" />
                    <Link to='/Familyplan' style={{ textDecoration: 'none', color: 'black' }}>
                        <h2>Family Planning and Contraception Advice</h2>
                    </Link>
                </div>

                <div className='servi2'>
                    <img src={viginal} alt="Vaginal Orifice Surgery" />
                    <Link to='/Vaginal' style={{ textDecoration: 'none', color: 'black' }}>
                        <h2>Vaginal Surgery</h2>
                    </Link>
                </div>

            </div>


            <div className='service'>
                <div className='servi2'>
                    <img src={cancer} alt="Safe Abortion" />
                    <Link to='/Gynecancer' style={{ textDecoration: 'none', color: 'black' }}>
                        <h2>Gynaecology Cancer Screening & Vaccination</h2>
                    </Link>
                </div>


            </div>



        </>
    );
};

export default Services;

