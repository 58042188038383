import React from 'react'
import Section from './Section'
import About from './About'
import Services from './Services'
import Reviews from './Reviews '
import Footer from './Footer'
import Doctorsection from './Doctorsection'


const Home = () => {
  return (

    <div className='Main'>
      <Section />
      {/* <About /> */}
      <Doctorsection/>
      <Services />
      <Reviews />
      <Footer />
      <br/>
    </div>


  )
}

export default Home