import React, { useEffect } from 'react';
import Footer from './Footer'
import singh from '../img/galleryback.jpg'

const Appointment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div className='Heading'>
                <h1>Contact Us</h1>
                <hr className="styled-hr" />
                <p>If you need effective immediate assistance, emergency treatment or a simple consultation.</p>
            </div>


            <div className='calls'>
                <a href="tel:6387313639"><i class="fa fa-phone"></i>   CALL NOW </a>

                <a href="https://wa.me/6387313639"><i class="fa fa-envelope"> </i> CHAT NOW </a>
            </div>


            <div className=' newcontact'>

                <div class="card">
                    <h2>Contact Information</h2>
                    <p><strong>Address:</strong> 5/41, Vinay Khand 5, Gomti Nagar, Lucknow, Uttar Pradesh 226010</p>
                    <p><strong>Phone:</strong> +91 6387313639</p>
                    <p><strong>Timings</strong> 2:00pm to 8:00pm (Mon. - Sat. ) </p>
                    <p><strong>Sunday - </strong> By Appointment Only</p>
                    <p><strong>Email:</strong> <a href="mailto:juhisisodia@gmail.com">juhisisodia@gmail.com</a></p>
                </div>


                <div className='derivates'>
                    <img src={singh}/>
                </div>

            </div>



            <Footer />
        </>
    )
}

export default Appointment
