import React, { useEffect } from 'react';
import lapro from '../img/lapro.jpg'; // Your image path
import Footer from '../component/Footer';

const Laproscop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>

      <div className='Heading'>
        <h1>Laparoscopic Gynaecological Procedures</h1>
        <hr className="styled-hr" />
      </div>

      <div className="laproscop-container">
       

        <div className="laproscop-content">
          <div className="laproscop-image">
            <img src={lapro} alt="Laparoscopic Gynaecological Procedure" />
          </div>

          <div className="laproscop-text">
            <br/>
            <p>
            Laparoscopic Gynaecological Procedures- Gynae Laparoscopy surgery is a minimally invasive procedure that allows the surgeon to operate inside abdomen by inserting instruments through small incisions. Benefits of this surgery includes less pain, less scarring, shorter hospital stay and fast recovery.
            </p>

            

            
          </div>
        </div>

        {/* Include your footer */}
        <Footer />
      </div>
    </>
  );
}

export default Laproscop;
