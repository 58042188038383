// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleNavItemClick = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div id="mySidebar" className={`sidebar ${isOpen ? 'open' : ''}`}>
                <a href="javascript:void(0)" className="closebtn" onClick={toggleSidebar}>&times;</a>
                <Link to="/Home" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                <Link to="/Aboutus" className="nav-link" onClick={handleNavItemClick}>About</Link>
                <Link to="/treatment" className="nav-link" onClick={handleNavItemClick}>Services</Link>
                <Link to="/Publication" className="nav-link" onClick={handleNavItemClick}>Publications</Link>
                <Link to="/Memberships" className="nav-link" onClick={handleNavItemClick}>Memberships</Link>
                <Link to="/appointment" className="nav-link" onClick={handleNavItemClick}>Contact Us</Link>
                <Link to="/ContactForm" className="nav-link" onClick={handleNavItemClick}>Appointment</Link>
                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>

                <div className='social' style={{ display: 'flex' }}>
                    <a href=""><i className="fa fa-facebook-f"></i></a>
                    <a href=""><i className="fa fa-instagram"></i></a>
                    <a href=""><i className="fa fa-youtube-play"></i></a>
                </div>
            </div>

            <p className="openbtn" onClick={toggleSidebar}>&#9776;</p>
        </>
    );
};

export default Navbar;
