import React, { useEffect } from 'react';
import viginal from '../img/vaginal.jpg';
import Footer from '../component/Footer';

const Vaginal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>

      <div className='Heading'>
        <h1>Vaginal Surgery</h1>
        <hr className="styled-hr" />
      </div>

      <div className="vaginal-container">

        <div className="vaginal-content">
          <div className="vaginal-image">
            <img src={viginal} alt="Vaginal Surgery" />
          </div>

          <div className="vaginal-text">
            <br/>
            <p>
            Vaginal Surgery- In this the entire surgery is performed vaginally and hence there is no abdominal scar. Time for post - operative recovery is lesser .
            </p>

            

           
          </div>
        </div>

        {/* Include Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Vaginal;
