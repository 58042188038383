import React, { useState, useEffect } from 'react';
import Footer from './Footer'

const BlogPostUpload = () => {

    
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Load posts from local storage on component mount
        const savedPosts = JSON.parse(localStorage.getItem('posts')) || [];
        setPosts(savedPosts);
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newPost = { title, content, image };
        const updatedPosts = [...posts, newPost];
        setPosts(updatedPosts);
        localStorage.setItem('posts', JSON.stringify(updatedPosts)); // Save to local storage
        setTitle('');
        setContent('');
        setImage(null);
    };

    const handleDelete = (index) => {
        const updatedPosts = posts.filter((_, i) => i !== index);
        setPosts(updatedPosts);
        localStorage.setItem('posts', JSON.stringify(updatedPosts)); // Update local storage
    };

    return (
        <>


            <div className='Heading'>
                <h1>Our Blogs</h1>
                <p>If you need effective immediate assistance, emergency treatment or a simple consultation.</p>
            </div>

            <div className="blog-post-upload-container">
                <h1>Upload  Blog Post</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Content</label>
                        <textarea
                            id="content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="image">Image</label>
                        <input
                            type="file"
                            id="image"
                            onChange={handleImageChange}
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>

            </div>

            <div className="blog-post-list">
                {posts.map((post, index) => (
                    <div key={index} className="blog-post-display">
                        <h2>{post.title}</h2>
                        <p>{post.content}</p>
                        {post.image && <img src={post.image} alt={post.title} />}
                        <button onClick={() => handleDelete(index)}>Delete</button>
                    </div>
                ))}
            </div>

            <Footer />

        </>


    );
};

export default BlogPostUpload;
