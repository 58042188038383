import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../img/whatsapp-call.gif'
import '../component/footer.css'
import '@fortawesome/fontawesome-free/css/all.min.css';


const Footer = () => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timerId);
    }, []);

    const formatTime = (time) => {
        return time.toLocaleTimeString();
    };

    const formatDate = (date) => {
        return date.toLocaleDateString();
    };
    return (
        <>
            <div className='footer'>


                <div className='foot'>

                    <div className='foot2'>
                        <center><h1 style={{ marginRight: '50px' }}>Dr. Juhi Sisodia</h1></center>
                        <h2>MBBS, DNB(Obgyn), FMAS</h2>

                        <p><i class="fa fa-map-marker"></i> <b>Address : </b>  5/41 , Vinay Khand, Gomti Nagar, Lucknow - 226010</p>
                        <p> <i class="fa fa-phone"></i> <b>Phone: </b> +91 6387313639</p>
                        <p> <i class="fa fa-envelope"></i> <b>Email: </b> juhisisodia@gmail.com</p>
                        <p> <i class="fa fa-clock-o"></i> <b>Timing :  </b> 2:00pm to 8:00pm (Mon. - Sat.)</p>
                    </div>

                </div>

                <div className='foot'>
                    
                   <br/><br/>
                   
                    <div className="social-media-icons">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-youtube"></i>
                        </a>


                    </div>

                    <br/><br/>

                    <Link  style={{ textDecoration: 'none' }}><h3><i class="fa fa-angle-right"></i>   CALL NOW   <a href='Tel:+91 6387313639'>+91 6387313639 </a> </h3> </Link>

                </div>

                <div className='foot'>
                   

                <h1> Use Link </h1>
                    <Link to='/' style={{ textDecoration: 'none' }}><h3 > <i class="fa fa-angle-right"></i> Home</h3></Link>
                    <Link to='/Aboutus' style={{ textDecoration: 'none' }}><h3><i class="fa fa-angle-right"></i> About</h3></Link>
                    <Link to='/appointment' style={{ textDecoration: 'none' }}> <h3> <i class="fa fa-angle-right"></i> Contact</h3></Link>
                    <Link to='/Gallery' style={{ textDecoration: 'none' }}><h3><i class="fa fa-angle-right"></i> Gallery</h3></Link>
                    <Link to='/Publication' style={{ textDecoration: 'none' }}><h3> <i class="fa fa-angle-right"></i> Publications</h3></Link>
                    <Link to='/Memberships' style={{ textDecoration: 'none' }}><h3><i class="fa fa-angle-right"></i> Membership</h3></Link>

                </div>


            </div>

            <div className='disclamer'>
                <h1>All Right Reserved Dr. Juhi Sisodia  Designed By Creative Digital World</h1>
            </div>

            <div className="whatsapp-button">
                <a href="https://wa.me/6387313639" target="_blank">

                    <img src={logo} />
                </a>
            </div>

        </>
    )
}

export default Footer