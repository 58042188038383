import React, { useEffect } from 'react';
import Footer from '../component/Footer';
import hrp from '../img/high.jpg'; // Image path

const Highrisk = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>

      <div className='Heading'>
        <h1> Normal &  High Risk Pregnancy</h1>
        <hr className="styled-hr" />
      </div>

      <div className="highrisk-container">


        <div className="highrisk-content">
          <div className="highrisk-image">
            <img src={hrp} alt="High Risk Pregnancy Care" />
          </div>

          <div className="highrisk-text">
            <br/>
            <p>
            Normal And High Risk Pregnancy- High risk pregnancy is a pregnancy where mother or fetus have a higher chance of experiencing complications than a normal pregnancy. Factors that contribute to high risk are - Medical condition , age , multiple pregnancy , life style pattern, etc.
            </p>

           

            
          </div>
        </div>

        
        <Footer />
      </div>
    </>
  );
};

export default Highrisk;
