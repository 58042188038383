import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../img/google.png'

const reviews = [
  {
    name: "Alice Nter Iram Atiqulla",
    review: "Dr. Juhi Sisodia is not just an extremely skilled and dedicated doctor but also a wonderful human being. She recently performed my cousin’s surgery and we can’t thank her enough for her cooperative and caring nature.",
    rating: 5,
  },
  {
    name: "Aastha Popli",
    review: "Had a wonderful experience throughout my treatment. Doctor Juhi is a very calm, composed, intelligent person who resolves each and every query of a patient with a smile and positive attitude.",
    rating: 5,
  },
  {
    name: "Abhinav Gupta",
    review: "One of the best gynecologists in Lucknow, she explained all the things very nicely. Our experience was very good.",
    rating: 5,
  },
  {
    name: "Deendayal",
    review: "My wife had severe heart disease and she needed Hysterectomy because of excess bleeding. Dr. Juhi Sisodia, along with her anesthetists, did the Hysterectomy with utmost care. I am very thankful to them for all their support.",
    rating: 5,
  },
  {
    name: "Divya Umrao",
    review: "Meri mumma ko heavy bleeding thi jisse mai jivisha hospital le kr ayi fir dr. Juhi ma'am ko dikhaye unhone best advice diya. Dr. Juhi maam is the best doctor. I highly recommend dr. Juhi ma'am for her fine surgery and humble nature. Thank you juhi ma'am.",
    rating: 5,
  },
  {
    name: "Kusum Yadav",
    review: "Dr. Juhi Sisodia has been my gynecologist for many years. She is very professional and very easy to speak with. I feel very comfortable, and she gives excellent medical care.",
    rating: 5,
  },
  {
    name: "Yamini Singh",
    review: "Excellent doctor. She develops a personal bonding with each of her patients and the baby. Our pregnancy/delivery went very smoothly throughout. Extremely approachable doctor. Forever thankful for our memorable pregnancy journey.",
    rating: 5,
  },
  {
    name: "Sneha Verma",
    review: "Juhi ma'am ne meri pregnancy me mera bht support Kiya hai. Pregnancy me thodi complications thi Jo ma'am ne bht easily handle ki h aur mujhe mentally bhi bht support Kiya hai. Ma'am doctor hone ke sath 2 ek acchi insan bhi hai. Thnku so much mam for your support during my pregnancy.",
    rating: 5,
  },
  {
    name: "Nisha Azad",
    review: "Doctor Juhi ma'am operated on my mother, I was very late, I had already done 2 LACS, it was the third operation and no one from my family came, my father is already employed, so he did not come, at that time along with the doctor, Juhi ma'am supported me a lot like a family, thank you so much ma'am.",
    rating: 5,
  },
  {
    name: "Faizuddin Siddiqui",
    review: "I had a very good experience with Dr. Juhi ji. I got the right advice and treatment. My experience was very good. She is very caring and supportive.",
    rating: 5,
  },
  {
    name: "Hina Khan",
    review: "Best gynecologist ...today my HSG done. Mam n bht achhe s process kiya as a process behavior very nice. I'm fully satisfied.",
    rating: 5,
  },
  {
    name: "Chitrakshi Dhillon",
    review: "She is an excellent doctor. She diagnoses the problem very well and gives the best medical advice. She is very humble and polite in her behavior too.",
    rating: 5,
  },
  {
    name: "Alok Kumar Singh",
    review: "WE HAD AN EXCELLENT EXPERIENCE WITH HER. SHE IS A THROUGH GENUINE LADY, VERY NICE DOCTOR AND WE WOULD RECOMMEND HIGHLY IF YOU WISH TO HAVE A SMOOTH PREGNANCY JOURNEY.",
    rating: 5,
  },
];

const ReviewSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="testimonial">
      <div className="slider-container">
        <center> <img src={logo} /></center>

        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="slider-item">
              <h3>   <i
                className="fab fa-google-plus"
                style={{
                  color: '#4285F4',
                  background: 'linear-gradient(to bottom right, #4285F4, #EA4335, #FBBC05, #34A853)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}
              ></i> {review.name}</h3>
              <p>  <i
                className="fab fa-google-plus"
                style={{
                  color: '#4285F4',
                  background: 'linear-gradient(to bottom right, #4285F4, #EA4335, #FBBC05, #34A853)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}
              ></i> {review.review}</p>
              <p>   <i
                className="fab fa-google-plus"
                style={{
                  color: '#4285F4',
                  background: 'linear-gradient(to bottom right, #4285F4, #EA4335, #FBBC05, #34A853)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}
              ></i> Rating: {"⭐".repeat(review.rating)}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewSlider;
