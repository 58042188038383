import React, { useEffect } from 'react';
import Footer from '../component/Footer';
import imf from '../img/imp.jpg'
import lkobs from '../img/obsticlko.jpg'
import assoc from '../img/associal.jpg'
import pri from '../img/gyneplogyst.png';
import indian from '../img/indian.jpg';


const Memberships = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>


      <div className='Heading'>
        <h1>Membership</h1>
        <hr className="styled-hr" />
      </div>

      <div className="membership-list">
        <ul>
          <li>
            Indian Medical Association (IMA)
          </li>
          <li>
            Lucknow Obstetrics and Gynaecology Society (LOGS)
          </li>
          <li>
            Association of Minimal Access Surgeons of India (AMASI)
          </li>
          <li>
            Association of Private Gynaecologists of Lucknow (APGL)
          </li>
          <li>
            Indian Society of Perinatology and Reproductive Biology (ISOPARB)
          </li>
          <li>
            The Federation of Obstetric and Gynecological Societies of India ( FOGSI)
          </li>
        </ul>
      </div>


      <Footer />
    </>
  );
}

export default Memberships;
