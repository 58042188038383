import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from './Footer';

const ContactForm = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [address, setAddress] = useState('');
    const [date, setDate] = useState('');
    const [reason, setReason] = useState('');
    const [image, setImage] = useState(null);
    const [contacts, setContacts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // Load contacts from local storage on component mount
        const savedContacts = JSON.parse(localStorage.getItem('contacts')) || [];
        setContacts(savedContacts);
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the message
        const message = `Appointment Details:
        Name: ${name}
        Contact: ${contact}
        Address: ${address}
        Date: ${date}
        Reason: ${reason}`;

        // WhatsApp API URL with the message pre-filled and contact number
        const whatsappURL = `https://wa.me/916387313639?text=${encodeURIComponent(message)}`;

        // Show SweetAlert2 success message
        Swal.fire({
            title: 'Appointment Booked',
            text: 'Your appointment has been booked successfully!',
            icon: 'success',
            confirmButtonText: 'Send via WhatsApp'
        }).then(() => {
            // Redirect to WhatsApp
            window.location.href = whatsappURL;
        });

        // Clear form after submission
        setName('');
        setContact('');
        setAddress('');
        setDate('');
        setReason('');
        setImage(null);
    };

    return (
        <>
            <div className='Heading'>
                <h1>Book Your Appointment</h1>
                <hr className="styled-hr" />
                <p> If you need  effective immediate assistance, emergency treatment or a simple consultation.</p>
            </div>

            <div className="contact-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="contact">Contact</label>
                        <input
                            type="text"
                            id="contact"
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date">Date</label>
                        <input
                            type="date"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="reason">Reason for Appointment</label>
                        <textarea
                            id="reason"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            required
                        />
                    </div>

                    <button type="submit">Submit</button>
                </form>

                <br />
                <p><b>Clinic Address:</b> 5/41, Vinay Khand 5, Gomti Nagar, Lucknow, Uttar Pradesh 226010</p>
            </div>

            <Footer />
        </>
    );
};

export default ContactForm;
