import React, { useEffect } from 'react';
import Footer from '../component/Footer';
import hrp from '../img/cancer screen.jpg';

const Gynecancer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div className='Heading'>
                <h1> Gynaecology Cancer Screening & Vaccination</h1>
                <hr className="styled-hr" />
            </div>

            <div className="highrisk-container">


                <div className="highrisk-content">
                    <div className="highrisk-image">
                        <img src={hrp} alt="High Risk Pregnancy Care" />
                    </div>

                    <div className="highrisk-text">

                        <br/>
                        <p>
                        Gyane Cancer  Screening & Vaccination- Cancer screening helps to detect cancer at early stage and have effective treatment at early stage itself. Ultrasound, tumor markers, liquid based cytology, HPV testing are included in this.
                        
                        </p>

                        <p>Cervical cancer vaccination is a safe and effective way to prevent cervical cancer and Genital Warts.</p>


                       
                    </div>
                </div>

                {/* Include Footer */}
                <Footer />
            </div>
        </>
    )
}

export default Gynecancer
