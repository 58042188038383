import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import './my.css';
import '../src/Treatments/treat.css';
import Home from './component/Home';
import Navbar from './component/Navbar';
import BlogPostUpload from './component/BlogPostUpload ';
import Appointment from './component/Appointment';
import Treatment from './component/Treatment';
import Aboutus from './component/Aboutus';
import DisplayContacts from './component/DisplayContacts';
import ContactForm from './component/ContactForm';
import Gallery from './component/Gallery';
import Contact from './component/Contact';
import Doctorsection from './component/Doctorsection';
import Laproscop from './Treatments/Laproscop';
import Highrisk from './Treatments/Highrisk';
import Familyplan from './Treatments/Familyplan';
import Vaginal from './Treatments/Vaginal';
import Gynecancer from './Treatments/Gynecancer';
import Publication from './Treatments/Publication';
import Memberships from './Treatments/Memberships';


function App() {
  return (
    <div className="Main shadow">
      <Router>
        
        <Navbar/>
        <Routes>
          <Route path="*" element={<Home />} />

          <Route path="/Post" element={<BlogPostUpload />} />
          <Route path="/Appointment" element={<Appointment />} />
          <Route path="/Treatment" element={<Treatment />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/ContactForm" element={<ContactForm />} />
          <Route path="/display-contacts" element={<DisplayContacts />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Doctorsection" element={<Doctorsection />} />


          <Route path="/Laproscop" element={<Laproscop />} />
          <Route path="/Highrisk" element={<Highrisk />} />
          <Route path="/Familyplan" element={<Familyplan />} />
          <Route path="/Vaginal" element={<Vaginal />} />
          <Route path="/Gynecancer" element={<Gynecancer />} />
          <Route path="/Publication" element={<Publication />} />
          <Route path="/Memberships" element={<Memberships />} />
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
