import React, { useEffect } from 'react';
import Footer from '../component/Footer'

const Publication = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div className='Heading'>
                <h1> Publications</h1>
                <hr className="styled-hr" />
            </div>

            <div className="publication-container">

                <h2 className="publication-title">Chapter In Book</h2>
                <p className="publication-item">1-	Samant M, <b>Sisodia J</b>, Rudra B. Infections in pregnancy. Practical guide for Obstetricians.  Notion Press, Chennai. 2021. Pg 314-329. </p>
                <p className="publication-item">2-	<b>Sisodia J</b>, Episiotomy. FOGSI FOCUS Birthing : Current concepts.
                    2023. Pg 111- 117.
                </p>
                <p className="publication-item">3-  Tuberculosis in pregnancy:- Latent and late . AICOG 2024 Workshop     Manual – Obstetric Medicine.</p>


                < br />
                <h2 className="publication-title">Scientific Publication</h2>
                <p class="publication-item">1-	Bose E, Samant M, <b>Sisodia J</b>.  Co- Twin IUD – A hospital  based study.
                    Indian Journal of Perinatology and Reproductive Biology, Vol 2, No.4, September 2012. Pg 31- 33.
                </p>

                <p className="publication-item">2-	Samant M, <b>Sisodia J</b>, Mishra S. Second Stage Caesarean Section – Evaluation of Patwardhan method. Indian  Journal of Perinatology and Reproductive Biology, Vol 5, No.3, July- September 2015. Pg 17- 18.</p>

                <p className="publication-item">3-	<b>Sisodia J</b>, Samant M. Scar site Ectopic pregnancy- series of 2 cases. Indian Journal Perinatology and Reproductive Biology, Vol 6, No.2, April – June 2016. Pg 30- 31.</p>

                <p className="publication-item">4-<b>Sisodia J</b> et al. International Journal Reproduction , Contraception, Obstetrics and Gyanecology and Antenatal thyroid screening and its clinical correlation. 2017 May; 6(5). </p>

                <p className="publication-item">5-	Samant M, <b>Sisodia J</b>. Screening for Congenital Hypothyroidism. Indian Journal of Perinatology and Reproductive Biology, Vol 11, No.01, Jan- Mar 2021. Pg  21- 23.</p>

            </div>


            <Footer />
        </>
    )
}

export default Publication
