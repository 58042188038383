import React, { useEffect, useState } from 'react';
import Footer from './Footer';

const DisplayContacts = () => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        // Load contacts from local storage
        const savedContacts = JSON.parse(localStorage.getItem('contacts')) || [];
        setContacts(savedContacts);
    }, []);

    const handleDelete = (index) => {
        const updatedContacts = contacts.filter((_, i) => i !== index);
        setContacts(updatedContacts);
        localStorage.setItem('contacts', JSON.stringify(updatedContacts)); // Update local storage
    };

    return (
        <>

            <div className='Heading'>
                <h1>Appointment Leads</h1>
                <p>Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
            </div>
            <div className="contact-list">
                <h1>Submitted Appointments</h1>
                {contacts.map((contact, index) => (
                    <div key={index} className="contact-item">
                        <h2>{contact.name}</h2>
                        <p>Contact: {contact.contact}</p>
                        <p>Address: {contact.address}</p>
                        <p>Date: {contact.date}</p>
                        <p>Reason: {contact.reason}</p>
                        {contact.image && <img src={contact.image} alt={contact.name} />}
                        <button onClick={() => handleDelete(index)}>Delete</button>
                    </div>
                ))}
            </div>

            <Footer/>

        </>
    );
};

export default DisplayContacts;
